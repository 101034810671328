import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getAdmins = createAsyncThunk(
  "managementApp/getAdmins",
  async () => {
    const res = await axios.get(`${api_domain}betshop/admin/list`);
    return res.data.betshop_admin || [];
  }
);

export const getSupplier = createAsyncThunk(
  "betshopAccountSupplierApp/supplier/getSuppliers",
  async ({ username, adminId, role }) => {
    try {
      let url = "";
      if (role == "betshop_operator") {
        if (!username && !adminId) url = `${api_domain}betshop/supplier/list/`;
        else if (!adminId)
          url = `${api_domain}betshop/supplier/list/${username}`;
        else url = `${api_domain}betshop/supplier/list/${username}/${adminId}`;
      } else if (role == "betshop_admin") {
        url = `${api_domain}betshop_admin/supplier/list/${username}`;
      }
      const response = await axios.get(url);
      return response.data.betshop_suppliers || [];
    } catch (error) {
      console.log("Error getting suppliers", error);
    }
  }
);

export const getFilteredTransactionLogs = createAsyncThunk(
  "financeApp/transactionLogs/getFilteredTransactionLogs",
  async (body) => {
    let url = "";
    if (body.user.role == "betshop_operator" || body.user.role === "betshop_staff") {
      url = `${api_domain}betshop/finance/transactions/${body.admin}/${body.startDate}/${body.endDate}`;
    }
    if (body.user.role == "betshop_admin") {
      url = `${api_domain}betshop_admin/finance/transactions/${body.user.uuid}/${body.startDate}/${body.endDate}`;
    }
    
    url += `?page=${pageNumber}&limit=${pageLimit}`

    const response = await axios.get(url);

    let transactions = (response.data.transactions === undefined ? null : response.data.transactions);
    let totalCount = (response.data.paginator === undefined ? 0 : response.data.paginator.total_count)
    
    return { transactions, totalCount };
  }
);

export const getAllTransactionLogs = createAsyncThunk(
  "financeApp/transactionLogs/getAllTransactionLogs",
  async ({ user, filter }) => {
    const {
      pageNumber,
      pageLimit,
      startDate,
      endDate,
      admin,
      supplier,
      username,
      module,
      currency,
      transactionType,
    } = filter;
    let url = "";
    if (user.role == "betshop_operator" || user.role === "betshop_staff") {
      url = `${api_domain}betshop/finance/transactions?`;
    } else if (user.role == "betshop_admin") {
      url = `${api_domain}betshop_admin/finance/transactions/${user.uuid}?`;
    } else if (user.role == "betshop_supplier") {
      url = `${api_domain}betshop_supplier/finance/transactions/${user.uuid}?`;
    } else if (user.role === "online_operator" || user.role === "online_staff") {
      url = `${api_domain}online/finance/transactions?`;
    }

    url += `page=${pageNumber}&limit=${pageLimit}&`

    if (startDate !== undefined && startDate !== "" && startDate !== "all") {
      url += `startDate=${startDate}&`;
    }
    if (endDate !== undefined && endDate !== "" && endDate !== "all") {
      url += `endDate=${endDate}&`;
    }
    if (admin !== undefined && admin !== "" && admin !== "all") {
      url += `admin=${admin}&`;
    }
    if (supplier !== undefined && supplier !== "" && supplier !== "all") {
      url += `supplier=${supplier}&`;
    }
    if (username !== undefined && username !== "") {
      url += `username=${username}&`;
    }
    if (module !== undefined && module !== "" && module !== "all") {
      url += `module=${module}&`;
    }
    if (currency !== undefined && currency !== "" && currency !== "all") {
      url += `currency=${currency.toUpperCase()}&`;
    }
    if (
      transactionType !== undefined &&
      transactionType !== "" &&
      transactionType !== "all"
    ) {
      url += `transactionType=${transactionType}&`;
    }

    const response = await axios.get(url);
    const data = await response.data.transactions;

    let transactions = (response.data.transactions === undefined ? null : response.data.transactions);
    let totalCount = (response.data.paginator === undefined ? 0 : response.data.paginator.total_count)

    return { transactions, totalCount };
  }
);

const transactionLogsAdapter = createEntityAdapter({});

export const { selectAll: selectAllTransactionLogs } =
  transactionLogsAdapter.getSelectors(
    (state) => state.financeApp.transactionLogs
  );

const transactionLogsSlice = createSlice({
  name: "financeApp/transactionLogs",
  initialState: transactionLogsAdapter.getInitialState({
    pageNumber: 0,
    pageLimit: 10,
    totalCount: 0
  }),
  reducers: {
    setPageNumber: {
      reducer: (state, action) => {
        state.pageNumber = action.payload;
      },
      prepare: (pageNumber) => {
        return {
          payload: pageNumber,
        };
      },
    },
    setPageLimit: {
      reducer: (state, action) => {
        state.pageLimit = action.payload;
      },
      prepare: (pageLimit) => {
        return {
          payload: pageLimit,
        };
      },
    },
  },
  extraReducers: {
    [getAllTransactionLogs.fulfilled]: (state, action) => {
      if (!action.payload) {
        transactionLogsAdapter.setAll(state, {});
        state.totalCount = 0;
      } else {
        transactionLogsAdapter.setAll(state, action.payload.transactions || []);
        state.totalCount = action.payload.totalCount;
      }
    },
    [getFilteredTransactionLogs.fulfilled]: (state, action) => {
      if (!action.payload) {
        transactionLogsAdapter.setAll(state, {});
        state.totalCount = 0;
      } else {
        transactionLogsAdapter.setAll(state, action.payload.transactions || []);
        state.totalCount = action.payload.totalCount;
      }
    },
  },
});

export const { setPageNumber, setPageLimit } = transactionLogsSlice.actions;

export const selectPageNumber = ({ financeApp }) =>
  financeApp.transactionLogs.pageNumber;
export const selectPageLimit = ({ financeApp }) =>
  financeApp.transactionLogs.pageLimit;
export const selectTotalCount = ({ financeApp }) =>
  financeApp.transactionLogs.totalCount;

export default transactionLogsSlice.reducer;
