import { combineReducers } from '@reduxjs/toolkit';
import providers from './providersSlice';
import countries from './countriesSlice';
import sports from './sportsSlice';
import tournaments from './tournamentsSlice';
import sportEvents from './sportEventsSlice';
import sportEvent from './sportEventSlice';
import markets from './marketsSlice';
import leagues from './leaguesSlice';
import groups from './groupsSlice';
import results from './resultManagementSlice';

const reducer = combineReducers({
  providers,
  countries,
  sports,
  tournaments,
  sportEvents,
  sportEvent,
  markets,
  leagues,
  groups,
  results,
});

export default reducer;