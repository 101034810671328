/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';
import FuseUtils from "@fuse/utils";
import axios from 'axios';
import {setNavigation} from "app/store/fuse/navigationSlice";
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert';
import {getContact} from "../main/apps/contacts/store/contactSlice";
import {getSupplierDetails} from "../main/accounts/suppliers/store/supplierDetailsSlice";

const api_domain = jwtServiceConfig.apiUrl;

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */

  if (typeof user === 'object') {
    user.loading = false;
  }
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example '/apps/academy'
  }

  return user;
});

export const changeUserPassword = createAsyncThunk('user/changePassword', async (body, { dispatch, getState }) => {
  try {
    const response = await axios.post(`${api_domain}auth/change-password`, body);
    toast.custom(
        <Alert variant="filled" severity='success'>
            Password has been successfully changed 
        </Alert>
        )
    return response.data.success;

  } catch (err) {
    if (err?.response?.data?.status) {
      toast.error(`${err.response.data.user_error}`, {
        duration: 2000, // Adjust the duration (in milliseconds) as needed
        position: 'top-center', // Position of the toast
        style: {
          fontSize: '18px',
          background: '#f44336', // Change the background color (e.g., red for error)
          color: '#fff', // Set text color for contrast
          padding: '8px',
          paddingInline: '50px',
          borderRadius: '8px',
        },
      });
      return err?.response;
    }

    return rejectWithValue(err.response.data)
  }
  
});

export const getUserSettings = createAsyncThunk('user/getSettings', async () => {
  const response = await axios.get(`${api_domain}auth/get-settings`);
  const data = response.data.system_change_settings;
  return data === undefined ? null : data;
});

export const getUser = createAsyncThunk('user/getUser', async () => {
  const response = await axios.get(`${api_domain}auth/getuser`);
  const data = await response.data.user;
  return data === undefined ? null : data;
});

export const changeUserSettings = createAsyncThunk('user/changeSettings', async (body, { dispatch, getState }) => {
  try {
    const response = await axios.post(`${api_domain}auth/change-settings`, body);
    toast.success(`Settings have been successfully changed`, {
      duration: 2000,
      position: 'top-center',
      icon: null,
      style: {
        fontSize: '16px',
        background: '#4caf50',
        color: '#fff',
        padding: '16px',
        borderRadius: '8px',
        minWidth: '200px', // Minimum width for the toast
        maxWidth: '600px', // Maximum width for longer text
        whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
        textAlign: 'center'
      },
    });
    return response.data.success;

  } catch (err) {
    if (err?.response?.data?.status) {
      toast.custom(
        <Alert variant="filled" severity='error'>
            {err.response.data.user_error}
        </Alert>
        )
      return err?.response;
    }

    return rejectWithValue(err.response.data)
  }
  
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/',
  });

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const hasPermission = (permission, permissionRoles) => (dispatch, getState) =>{
  const { user } = getState();

  return FuseUtils.hasPermission(permission, permissionRoles, user.role, user.permissions);
}

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  role: [], // guest
  from: "db", // "db" or "firebase
  data: {
    displayName: 'John Doe',
    photoURL: 'assets/images/avatars/brian-hughes.jpg',
    email: 'johndoe@withinpixels.com',
    shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'],
  },
  permissions: [],
  loading: false,
  search: ''
};

const userAdapter = createEntityAdapter(initialState);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setBalance: {
      reducer: (state, action) => {
        state.data.accountBalance = action.payload;
      },
      prepare: (event) => ({ payload: event || '' }),
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut, setSearch, setBalance } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;
// export const selectUserPending = ({ user }) => user.data.isSetUserPending;

export const selectSearch = ({ user }) => user.search;


export default userSlice.reducer;
