import themesConfig from "app/configs/themesConfig";
import i18n from "src/i18n";
import authRoles from "../auth/authRoles";

const settingsConfig = {
  layout: {
    style: "layout2", // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || "ltr", // rtl, ltr
  theme: {
    main: themesConfig.default,
    navbar: themesConfig.defaultDark,
    toolbar: themesConfig.default,
    footer: themesConfig.defaultDark,
  },

  /*
   To make whole app auth protected by default set defaultAuth:['admin','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */

  // defaultAuth: ['admin', 'affiliate', 'affiliate_owner'],
  defaultAuth: authRoles.user,
  betTypes: [
    {
      id: "Live",
      name: "Live",
    },
    {
      id: "unplayed",
      name: "PreMatch",
    },
  ],
  dictionaryGroup: [
    {
      id: "admin",
      name: "Admin_Translates",
    },
    {
      id: "website",
      name: "Website_Translates",
    },
    {
      id: "sport",
      name: "Sport_Translates",
    },
    {
      id: "league",
      name: "League_Translates",
    },
    {
      id: "country",
      name: "Country_Translates",
    },
    {
      id: "team",
      name: "Team_Name_Translates",
    },
    {
      id: "collection",
      name: "Collection_Translates",
    },
    {
      id: "marketGroup",
      name: "Market_Group_Translates",
    },
  ],
  roles: [
    {
      id: "manager",
      name: "Manager",
    },
    {
      id: "staff",
      name: "Staff",
    },
  ],
  departmentUserRoles: [
    {
      id: "staff",
      name: "Staff",
    },
  ],
  systemTypes: [
    {
      value: 1,
      label: "Online",
    },
    {
      value: 2,
      label: "Betshop",
    },
  ],
  allStatuses: [
    {
      value: "all",
      label: i18n.t("All"),
    },
    {
      value: "waiting",
      label: i18n.t("Waiting"),
    },
    {
      value: "won",
      label: i18n.t("Won"),
    },
    {
      value: "lost",
      label: i18n.t("Lost"),
    },
    {
      value: "cashedOut",
      label: i18n.t("Cash_Out"),
    },
    {
      value: "cancel",
      label: i18n.t("Cancel"),
    },
    {
      value: "refunded",
      label: i18n.t("Refunded"),
    },
  ],
  allGameType: [
    {
      value: "all",
      label: i18n.t("All"),
    },
    {
      value: "live",
      label: i18n.t("Live"),
    },
    {
      value: "prematch",
      label: i18n.t("PreMatch"),
    },
    // {
    //   value: 'combo',
    //   label: 'Combo'
    // }
  ],
  allCouponType: [
    {
      value: "all",
      label: i18n.t("All"),
    },
    {
      value: "single",
      label: i18n.t("Single"),
    },
    {
      value: "system",
      label: i18n.t("System"),
    },
    {
      value: "combo",
      label: i18n.t("Combo"),
    },
  ],
  currency: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
  ],

  currencies: [
    {
      id: "TRY",
      name: "TRY",
    },
    {
      id: "USD",
      name: "USD",
    },
    {
      id: "EUR",
      name: "EUR",
    },
    {
      id: "USDT",
      name: "USDT",
    },
  ],
  amountStatus: [
    {
      value: "Deposit",
      label: i18n.t("Deposit"),
    },
    {
      value: "Withdraw",
      label: i18n.t("Withdraw"),
    },
  ],
  currencyType: [
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
    {
      value: "eur",
      label: "EUR",
    },
    {
      value: "usdt",
      label: "USDT",
    },
  ],
  systemType: [
    {
      value: "Doxwin",
    },
    {
      value: "Nexbet",
    },
  ],
  games: [
    {
      value: "All",
      label: "All",
      translation: "All",
    },
    {
      value: "Sports",
      label: "Sports",
      translation: "Sports",
    },
    {
      value: "Casino",
      label: "Casino",
      translation: "Casino",
    },
    {
      value: "Live Casino",
      label: "Live Casino",
      translation: "Live_Casino",
    },
    {
      value: "Poker",
      label: "Poker",
    },
  ],
  transactions: [
    {
      value: "all",
      label: "All",
    },
    {
      value: "try",
      label: "TRY",
    },
    {
      value: "usd",
      label: "USD",
    },
  ],
  clientTypes: [
    {
      value: "vip",
      label: "VIP",
    },
  ],
  modules: [
    {
      value: "Sports",
      label: i18n.t("Sports"),
    },
    {
      value: "Casino",
      label: i18n.t("Casino"),
    },
    {
      value: "Payment",
      label: i18n.t("Payment"),
    },
  ],
  cmsLanguages: [
    {
      value: "en",
      label: "English",
    },
    {
      value: "tr",
      label: "Turkish",
    },
    {
      value: "ar",
      label: "Arabic",
    },
    {
      value: "es",
      label: "Spanish",
    },
    {
      value: "pt",
      label: "Portuguese",
    },
    {
      value: "ru",
      label: "Russian",
    },
    {
      value: "fr",
      label: "French",
    },
    {
      value: "de",
      label: "German",
    },
    {
      value: "zh",
      label: "Chinese",
    },
    {
      value: "ja",
      label: "Japanese",
    },
    {
      value: "it",
      label: "Italian",
    },
    {
      value: "ko",
      label: "Korean",
    },
    {
      value: "fa",
      label: "Persian",
    },
    {
      value: "id",
      label: "Indonesian",
    },
    {
      value: "sr",
      label: "Serbian",
    },
    {
      value: "sq",
      label: "Albanian",
    },
  ],
  cmsPromotionSectionTypes: [
    {
      value: "popular",
      label: i18n.t("Popular"),
    },
    {
      value: "welcome",
      label: i18n.t("Welcome"),
    },
    {
      value: "sports",
      label: i18n.t("Sports"),
    },
    {
      value: "casino",
      label: i18n.t("Casino"),
    },
    {
      value: "live_casino",
      label: i18n.t("Live_Casino"),
    },
    {
      value: "games",
      label: i18n.t("Games"),
    },
    {
      value: "poker",
      label: i18n.t("Poker"),
    },
    {
      value: "home",
      label: i18n.t("Home"),
    },
  ],
  cmsLandingpageSectionTypes: [
    {
      value: "main_banner",
      label: "Main Banner",
    },
    {
      value: "game_modules",
      label: "Game Modules",
    },
  ],
  cmsBannerSectionTypes: [
    {
      value: "sports",
      label: i18n.t("Sports"),
    },
    {
      value: "casino",
      label: i18n.t("Casino"),
    },
    {
      value: "live_casino",
      label: i18n.t("Live_Casino"),
    },
    {
      value: "games",
      label: i18n.t("Games"),
    },
    {
      value: "poker",
      label: i18n.t("Poker"),
    },
    {
      value: "virtuals",
      label: i18n.t("Virtuals"),
    },
    {
      value: "home",
      label: i18n.t("Home"),
    },
  ],
  cmsPageOfContentsSectionTypes: [
    {
      value: "company",
      label: i18n.t("Company"),
    },
    {
      value: "terms_and_privacy",
      label: i18n.t("Terms_and_Privacy"),
    },
    {
      value: "rules",
      label: i18n.t("Rules"),
    },
    {
      value: "responsible_gambling",
      label: i18n.t("Responsible_Gambling"),
    },
    {
      value: "faq",
      label: i18n.t("FAQ"),
    },
  ],
  paymentGateways: [
    // { value: "Maxipara", label: "Maxipara" },
    { value: "MPay", label: "MPay" },
    { value: "CoinPayment", label: "Coinpayment" },
    // { value: "Jetpay", label: "Jetpay" },
  ],
  deviceTypes: [
    { value: 1, label: i18n.t("Desktop") },
    { value: 2, label: i18n.t("Mobile") },
  ],
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: "/",
};

export default settingsConfig;
