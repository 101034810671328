import Alert from "@mui/material/Alert";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getSupplierDetails = createAsyncThunk(
  "betshopAccountSupplierApp/supplierDetails/getSupplierDetails",
  async ({ userId, role }) => {
    let url = "";
    if (role == "betshop_operator" || role === "betshop_staff") {
      url = `${api_domain}betshop/supplier/${userId}`;
    } else if (role === "betshop_admin") {
      url = `${api_domain}betshop_admin/supplier/${userId}`;
    }
    const response = await axios.get(url);
    const data = await response.data.user;
    return data === undefined ? null : data;
  }
);

export const addBalance = createAsyncThunk(
  "betshopAccountSupplierApp/supplier/addBalance",
  async ({ data, role }, { rejectWithValue, dispatch }) => {
    try {
      let url = "";
      if (role == "betshop_operator" || role === "betshop_staff") {
        url = `${api_domain}betshop/supplier/addBalance`
      } else if (role === "betshop_admin") {
        url = `${api_domain}betshop_admin/supplier/addBalance`
      }
      const response = await axios.put(
        url,
        data
      );
      toast.success('Balance has been successully added', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });

      return response.data.user;
    } catch (err) {
      toast.error(`${err.response.data.user_error}`, {
        duration: 2000, // Adjust the duration (in milliseconds) as needed
        position: 'top-center', // Position of the toast
        style: {
          fontSize: '18px',
          background: '#f44336', // Change the background color (e.g., red for error)
          color: '#fff', // Set text color for contrast
          padding: '8px',
          paddingInline: '50px',
          borderRadius: '8px',
        },
      });
      if (err?.response?.data?.status) {
        return err?.response;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSupplier = createAsyncThunk(
  "betshopAccountSupplierApp/supplier/saveAdmin",
  async ({body, role}) => {
    try {
      let url = "";
      if (role == "betshop_operator" || role === "betshop_staff") {
        url = `${api_domain}betshop/supplier/save/${body.userId}`;
      } else if (role === "betshop_admin") {
        url = `${api_domain}betshop_admin/supplier/save/${body.userId}`;
      }

      let data = {
        role: body.role,
        first_name: body.first_name,
        sur_name: body.sur_name,
        last_name: body.last_name,
        phone: body.phone,
        email: body.email,
        user_limit: body.user_limit,
        currency: body.currency,
        username: body.username,
        password: body.password,
        country: body.country
      };
      const response = await axios.put(
        url,
        data
      );

      toast.success('User has been successfully saved', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.success;
    } catch (err) {
      if (!err?.response?.data?.success) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const supplierDetailsAdapter = createEntityAdapter({});

const supplierDetailsSlice = createSlice({
  name: "betshopAccountSupplierApp/supplierDetails",
  initialState: supplierDetailsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [getSupplierDetails.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export const selectedSupplier = ({ betshopAccountSupplierApp }) =>
  betshopAccountSupplierApp.supplierDetails;

export default supplierDetailsSlice.reducer;
