import Typography from "@mui/material/Typography";

import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider } from "@mui/material/styles";
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectUser } from "app/store/userSlice";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import { authRoles } from "src/app/auth";
import AdjustFontSize from "../../shared-components/AdjustFontSize";
import FullScreenToggle from "../../shared-components/FullScreenToggle";
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import NavigationSearch from "../../shared-components/NavigationSearch";
import UserMenu from "../../shared-components/UserMenu";
import ChatPanelToggleButton from "../../shared-components/chatPanel/ChatPanelToggleButton";
import NotificationPanelToggleButton from "../../shared-components/notificationPanel/NotificationPanelToggleButton";
import QuickPanelToggleButton from "../../shared-components/quickPanel/QuickPanelToggleButton";

function ToolbarLayout2(props) {
  const user = useSelector(selectUser);
  const AuthRoles = authRoles;
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const toolbarTheme = useSelector(selectToolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md", props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="p-0 lg:px-24 min-h-48 md:min-h-64">
          {config.navbar.display && (
            <Hidden lgUp>
              <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
            </Hidden>
          )}

          <div className="flex flex-1">
            <Hidden lgDown>
              {/* <NavigationShortcuts /> */}
              <NavigationSearch />
            </Hidden>
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <LanguageSwitcher />

            {AuthRoles.affiliate.includes(user.role) ? (
              <>
                {/* <div className="limit" style={{ display: "flex", alignItems: "flex-end", marginRight: "20px", marginLeft: "15px" }}>
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Balance:
                  </Typography>
                  <Typography sx={{ ml: 0.5 }} className="email text-13 whitespace-nowrap font-medium" color="text.secondary">
                    2358
                  </Typography>
                </div> */}
                <div
                  className="limit"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: "6px 10px",
                    borderRadius: "8px",
                    background: "#FAE3E9"
                  }}
                >
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Domain:
                  </Typography>
                  <Typography
                    sx={{ ml: 0.5 }}
                    className="email text-13 whitespace-nowrap font-medium"
                  >
                    {user.domain}
                  </Typography>
                </div>
                <div
                  className="limit"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: "6px 10px",
                    borderRadius: "8px",
                    background: "#EAF5F0"
                  }}
                >
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    System ID:
                  </Typography>
                  <Typography
                    sx={{ ml: 0.5 }}
                    className="email text-13 whitespace-nowrap font-medium"
                  >
                    {user.systemID}
                  </Typography>
                </div>

                {/* <div className="limit" style={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Limit:
                  </Typography>
                  <Typography sx={{ ml: 0.5 }} className="email text-13 whitespace-nowrap font-medium" color="text.secondary">
                    10000
                  </Typography>
                </div> */}
              </>
            ) : AuthRoles.betshop_online.includes(user.role) ? (
              <>
                <div
                  className="limit"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: "6px 10px",
                    borderRadius: "8px",
                    background: "#4f46e51c"
                  }}
                >
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Balance:
                  </Typography>
                  <Typography
                    sx={{ ml: 0.5 }}
                    className="email text-13 whitespace-nowrap font-medium"
                  >
                    {user.data.accountBalance} {user.data.currency}
                  </Typography>
                </div>
                {/* <div className="limit" style={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Limit:
                  </Typography>
                  <Typography sx={{ ml: 0.5 }} className="email text-13 whitespace-nowrap font-medium" color="text.secondary">
                    10000
                  </Typography>
                </div> */}
                <div
                  className="limit"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: "6px 10px",
                    borderRadius: "8px",
                    background: "#FAE3E9"
                  }}
                >
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    Domain:
                  </Typography>
                  <Typography
                    sx={{ ml: 0.5 }}
                    className="email text-13 whitespace-nowrap font-medium"
                  >
                    {user.domain}
                  </Typography>
                </div>
                <div
                  className="limit"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: "6px 10px",
                    borderRadius: "8px",
                    background: "#EAF5F0"
                  }}
                >
                  <Typography className="username text-14 whitespace-nowrap font-medium">
                    System ID:
                  </Typography>
                  <Typography
                    sx={{ ml: 0.5 }}
                    className="email text-13 whitespace-nowrap font-medium"
                  >
                    {user.systemID}
                  </Typography>
                </div>
              </>
            ) : AuthRoles.admin.includes(user.role) ? (
              <>
                {/* <AdjustFontSize /> */}

                <FullScreenToggle />

                {/* <NavigationSearch /> */}

                <Hidden lgUp>
                  <ChatPanelToggleButton />
                </Hidden>

                {/* <QuickPanelToggleButton /> */}

                {/* <NotificationPanelToggleButton /> */}
              </>
            ) : (
              <>
                <AdjustFontSize />

                <FullScreenToggle />

                <NavigationSearch />

                <Hidden lgUp>
                  <ChatPanelToggleButton />
                </Hidden>

                <QuickPanelToggleButton />

                <NotificationPanelToggleButton />
              </>
            )}

            <UserMenu />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout2);
