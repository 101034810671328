import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getSports = createAsyncThunk('sportDataApp/sports/getSports', async ({currentLanguageId, betType}) => {
  const response = await axios.get(`${api_domain}sports/${currentLanguageId}/${betType}`);
  return response.data.sports;
});
export const getSportsForSportEvents = createAsyncThunk('sportDataApp/sports/getSports', async ({currentLanguageId, betType}) => {
  const response = await axios.get(`${api_domain}sportEvents/sports/${currentLanguageId}/${betType}`);
  return response.data.sports;
});

const sportsAdapter = createEntityAdapter({});

export const {
  selectById: selectSportById,
  selectIds: selectSportIds,
  selectEntities: selectSportEntities,
  selectAll: selectAllSports,
  selectTotal: selectTotalSports,
} = sportsAdapter.getSelectors((state) => state.sportDataApp.sports);

const sportsSlice = createSlice({
  name: 'sportDataApp/sports',
  initialState: sportsAdapter.getInitialState({
    sportId: 0,
  }),
  reducers: {
    setSportId: {
      reducer: (state, action) => {
        state.sportId = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || 0 }),
    },},
  extraReducers: {
    // [getSports.fulfilled]: (state, action) => {
    //   sportsAdapter.upsertMany(state, action.payload)
    // }
    [getSports.fulfilled]: (state, action) => {
      if (!action.payload) {
        sportsAdapter.setAll(state, {})
      } else {
        sportsAdapter.setAll(state, action.payload)
      }
    },
    [getSportsForSportEvents.fulfilled]: (state, action) => {
      if (!action.payload) {
        sportsAdapter.setAll(state, {})
      } else {
        sportsAdapter.setAll(state, action.payload)
      }
    }
  },
});

export const { setSportId } = sportsSlice.actions;
export const selectSportId = ({ sportDataApp }) => sportDataApp.providers.sportId;

export default sportsSlice.reducer;