import { lazy } from 'react';
import authRoles from "../../auth/authRoles";

const Coupon = lazy(() => import('./coupon/Coupon'));
const DepartmentUsers = lazy(() => import('./department-users/'));
const User = lazy(() => import('./user/User'));
const Finance = lazy(() => import('./finance/Finance'));
const Systems = lazy(() => import('./systems/'));
const System = lazy(() => import('./system/'));

const managementConfigs = [{
  settings: {
    layout: {},
  },
  auth: authRoles.manager_staff,
  routes: [
    {
      path: 'management/coupon',
      element: <Coupon />,
    },
    {
      path: 'management/department-users',
      element: <DepartmentUsers />,
    },
    {
      path: 'management/department-user/:userId/*',
      element: <User />,
    },
    {
      path: 'management/finance',
      element: <Finance />,
    },
    {
      path: 'management/systems',
      element: <Systems />,
    },
    {
      path: 'management/system/:systemId/*',
      element: <System />,
    },
    {
      path: 'management/systems',
      element: <Systems />,
    },
  ],
}];

export default managementConfigs;