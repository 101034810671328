/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['manager'],
  staff: ['staff'],
  
  all_staff: ['staff', 'betshop_staff', 'online_staff'],
  betshop_staff: [ "betshop_staff"],
  online_staff: ['online_staff'],

  betshop_operator: ['betshop_operator'],
  online_operator: ['online_operator'],
  online_betshop_staff: ['online_staff', 'betshop_staff'],

  admin_stuff: ['manager', 'staff'],
  manager_staff: ['staff', 'manager', 'betshop_operator', 'online_operator', 'online_staff', 'betshop_staff', 'betshop_admin', 'betshop_supplier'],

  controller: ['manager', 'staff', 'affiliate', 'affiliate_owner'],
  user: [
    'manager', 'staff', 'affiliate', 'affiliate_owner', 'user', 'betshop_operator', 'online_operator', 'betshop_admin', 'betshop_supplier',
    "online_staff", 'betshop_staff'
  ],
  affiliate: ['affiliate', 'affiliate_owner'],
  betshop: ['betshop_operator','betshop_staff'],
  online: ['online_operator', 'online_staff'],
  manager: ['manager', 'betshop_operator', 'online_operator'],
  staff_menu: ['manager', 'betshop_operator', 'online_operator'],
  operator: ['betshop_operator', 'online_operator'],
  cms: ['betshop_operator', 'online_operator'],
  onlyGuest: [],
  betshop_admin: ['betshop_admin'],
  betshop_account: ['betshop_operator', 'betshop_admin', 'betshop_staff'],
  betshop_user: ['betshop_operator', 'betshop_admin', 'betshop_supplier', 'betshop_staff'],
  // setting: ['manager', 'betshop_operator', 'online_operator', 'betshop_admin'],
  setting: ['manager', 'betshop_operator', 'online_operator', 'betshop_admin'],
  // finance: ['manager', 'betshop_operator', 'online_operator', 'betshop_admin', 'betshop_supplier'],
  finance: ['manager', 'betshop_operator', 'online_operator', 'betshop_admin', 'betshop_supplier'],
  finance_staff: ['manager', 'betshop_operator', 'online_operator', 'betshop_admin', 'betshop_supplier', "staff"],
  // coupon: ['betshop_supplier', 'online_operator', 'betshop_operator', 'betshop_admin'],
  coupon: ['betshop_supplier', 'online_operator', 'betshop_operator', 'betshop_admin'],
  // accounts: ['betshop_operator', 'betshop_admin', 'online_operator', 'betshop_supplier'],
  accounts: ['betshop_operator', 'betshop_admin', 'online_operator', 'betshop_supplier'],
  supplier: ['betshop_supplier'],
  // reports: ['betshop_admin', 'betshop_supplier'],
  reports: ['betshop_admin', 'betshop_supplier', "betshop_operator", "online_operator", "affiliate"],
  // dashboard: ['betshop_admin', 'betshop_supplier'],
  dashboard: ['betshop_admin', 'betshop_supplier', 'betshop_staff', "betshop_operator", "online_operator", "online_staff", "affiliate"],
  account_user: ['betshop_operator', 'betshop_admin', 'betshop_supplier'],
  access_logs: ['manager', 'betshop_operator', 'betshop_admin', 'betshop_supplier', 'online_operator'],
  // betshop_online: ['betshop_supplier', 'online_operator', 'betshop_operator', 'betshop_admin']
  betshop_online: ['betshop_supplier', 'online_operator', 'online_staff', 'betshop_operator', 'betshop_admin', 'betshop_staff', 'staff'],
  betshop_all: ['betshop_operator','betshop_admin','betshop_supplier','betshop_staff']
};

export default authRoles;
