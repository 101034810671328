import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import { SortTableHead } from "app/shared-components/SortTableHead/SortTableHead";
import { useState } from "react";
import { ifIncludes, isEqual } from "src/app/utils/string";
// import { Chip } from "./Chip";
import * as React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SelectGroup } from "app/shared-components/SelectGroup";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useDispatch } from "react-redux";
import { setResultManagementData } from "../store/resultManagementSlice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const outcomeOptions = [
  { label: "Waiting", value: "Waiting" },
  { label: "Won", value: "Won" },
  { label: "Lost", value: "Lost" },
  { label: "Canceled", value: "Canceled" },
  { label: "Refunded", value: "Refunded" },
  { label: "CashedOut", value: "CashedOut" },
]

const tableHeaders = [
  { id: "event_id", title: "Betslip ID" },
  { id: "start_date", title: "Date", wrapper: (value) => format(new Date(value), "yyyy MMM dd hh:mm") },
  { id: "event_name", title: "Event Name" },
  { id: "sport_type", title: "Sport Type" },
  { id: "country", title: "" },
  { id: "league_tournament", title: "Currency" },
  { id: "active", title: "Active" },
];

const OutcomeSelect = ({ label, selectedValue, onChange }) => {
  return (
    <Box className="flex items-stretch gap-[4px]">
      <Box className="min-w-[120px] border rounded-[12px] flex justify-center items-center">{label}</Box>
      <SelectGroup options={outcomeOptions} value={selectedValue} onChange={onChange} />
    </Box>
  )
}

const outcomeSorted = (data) => {
  // Define the custom order including "U" and "V"
  const order = ["1", "X", "2", "U", "V", "1X", "12", "X2"];

  const newData = [...data];

  // Sort the array with custom logic
  newData.sort((a, b) => {
    const keyA = Object.keys(a)[0];
    const keyB = Object.keys(b)[0];

    // Get the indices of the keys in the custom order
    const indexA = order.indexOf(keyA);
    const indexB = order.indexOf(keyB);

    // Handle keys not in the custom order: place them at the end
    if (indexA === -1 && indexB === -1) return 0; // Keep the same order if both are not in custom order
    if (indexA === -1) return 1; // Move a to the end
    if (indexB === -1) return -1; // Move b to the end

    // Sort based on the custom order
    return indexA - indexB;
  });

  return newData;
}

const outcomeLabels = {
  "X": "X",
  "U": "Under",
  "O": "Over",
  "1X": "1X",
  "12": "12",
  "X2": "X2",
  "no": "No",
  "yes": "Yes",
  "Yes": "Yes",
  "No": "No",
  "X/1": "X/1",
  "1/1": "1/1",
  "2/1": "2/1",
  "X/2": "X/2",
  "2/2": "2/2",
  "1/X": "1/X",
  "1/2": "1/2",
  "X/X": "X/X",
  "2/X": "2/X",
  "1=2": "1=2",
  "1>2": "1>2",
  "2>1": "2>1",
  "2 >1": "2>1"
}

export default function ResultTable(props) {
  const { rows, headCells, filter } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);
  const [couponUserDetails, setCouponUserDetails] = useState();
  const [columns, setColumns] = useState([]);
  const [winningRecordsVisible, setWinningRecordsVisible] = useState(false);
  const md = useScreenMd();

  const dispatch = useDispatch();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    setOpen(true);
    setCouponUserDetails(row);

    let combs = []
    for (let i = 2; i < row.coupon_details?.length; i++) {
      let res = combinations(row.coupon_details, i);
      combs = combs.concat(res);
    }
    setColumns(combs);
  };

  const combinations = (arr, k) => {
    const result = [];

    function helper(start, combo) {
      if (combo.length === k) {
        result.push([...combo]);
        return;
      }
      for (let i = start; i < arr.length; i++) {
        combo.push(arr[i]);
        helper(i + 1, combo);
        combo.pop();
      }
    }

    helper(0, []);
    return result;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleColumnStatus = (column) => {
    let status = "Won";
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Waiting") {
        status = "Waiting";
        break;
      }
    }
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Lost") {
        status = "Lost";
        break;
      }
    }
    return status
  }

  const handleColumnWinnings = (column) => {
    let winnings = 1.0;
    for (let i = 0; i < column.length; i++) {
      winnings *= column[i].odds_price;
    }
    return winnings.toFixed(2);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = React.useMemo(
    () => rows,

    // () =>
    //   rows.filter((row) => {
    //     const {
    //       id: rBetslipID,
    //       username: rUsername,
    //       currency: rCurrency,
    //       coupon_type: rCouponType,
    //       game_type: rGameType,
    //       status: rStatus,
    //     } = row;

    //     return (
    //       ifIncludes(rBetslipID.toString(), Betslip_Id) &&
    //       ifIncludes(rUsername, Username) &&
    //       (Currency === "all" || isEqual(rCurrency, Currency)) &&
    //       (Coupon_type === "all" || isEqual(rCouponType, Coupon_type)) &&
    //       (Game_type === "all" || isEqual(rGameType, Game_type)) &&
    //       (Status === "all" || isEqual(rStatus, Status))
    //     );
    //   }),
    [rows, filter]
  );

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  const outcomeGroup = React.useMemo(() => {
    if (!couponUserDetails) return [];

    const { outcomes } = couponUserDetails;
    const result = {};

    for (const outcome of outcomes) {
      const { outcome_info, status } = outcome;
      const info = outcome_info.split(";")[0];
      const team = outcome_info.split(";")[1];
      const newData = [
        ...(result[info] || []),
        {
          [team]: status
        }
      ];
      result[info] = outcomeSorted(newData);
    }

    return result;
  }, [couponUserDetails]);

  const handleOutcomeChange = (outcome_info, status) => {
    setCouponUserDetails(detail => ({
      ...detail,
      outcomes: detail.outcomes.map(outcome => outcome.outcome_info === outcome_info ? {
        ...outcome,
        status,
      } : outcome),
    }))
  }

  const handleSave = () => {
    if (!couponUserDetails) return;

    const { event_id, outcomes } = couponUserDetails;
    const data = outcomes.map(outcome => ({
      event_id,
      market_id: outcome.market_id,
      outcome_ref_id: outcome.outcome_info,
      Status: outcome.status,
    }));

    dispatch(setResultManagementData({
      event_id,
      body: {
        outcome_infos: data
      }
    }));
  }

  return (
    <Box className="max-w-[90%] mx-auto">
      <Paper className="w-full mb-[24px] overflow-hidden">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <SortTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody className="border-1">
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                console.log(row.start_date, 'start_date')
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className="cursor-pointer"
                    sx={{
                      background: index % 2 === 0 ? '#EAEAEF' : '#fff'
                    }}
                  >
                    <TableCell
                      sx={{
                        py: "4px",
                        pr: "36px",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      {row.event_id}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.start_date && format(new Date(row.start_date), "yyyy MMM dd hh:mm")}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.event_name}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.game_type === "unplayed" ? "Prematch" : "Live"}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                      {row.sport_type}
                    </TableCell>
                    <TableCell
                      sx={{ py: "4px", fontSize: "12px", textAlign: "left" }}
                    >
                      {row.country}
                    </TableCell>
                    <TableCell
                      sx={{ py: "4px", fontSize: "12px", textAlign: "left" }}
                    >
                      {row.tournament}
                    </TableCell>
                    <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "left" }}>
                      Details
                      <IconButton>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          ".MuiPaper-root": {
            overflow: "scroll",
            maxWidth: {
              xs: '100%',
              md: '65%'
            },
            minWidth: {
              md: '50%'
            }
          }
        }}
      >
        <Box className="relative">

          <Stack spacing="12px">
            <Box
              aria-label="slipHeader"
            >
              <Stack
                className="px-[24px] py-[24px] bg-[#2C374F] text-white flex-col justify-between items-start gap-[20px]"
              >
                <Stack
                  className="flex-row justify-between w-full items-center"
                >
                  <Typography className="text-[18px] font-semibold">{couponUserDetails?.event_name}</Typography>
                  <IconButton
                    className="text-[#2C374F] bg-white -mt-[10px] hover:bg-white"
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Stack
                  className="flex-row justify-between items-center w-full"
                >
                  <Typography>{`${couponUserDetails?.country} / ${couponUserDetails?.tournament}`}</Typography>
                  <Typography>{couponUserDetails && format(new Date(couponUserDetails?.start_date), "yyy MMM dd, hh:mm")}</Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack gap="12px" className="py-[8px] px-[16px] text-[#111827]">
                <Typography className="text-[16px] font-semibold">Markets</Typography>
                {Object.entries(outcomeGroup).map(([key, outcomes]) => {
                  const { event_name } = couponUserDetails;
                  const teamNames = event_name.split(" vs ");
                  const keyStrings = key.split(",");
                  let keyValue = keyStrings[0];
                  keyValue = keyValue.replace("%1", teamNames[0]).replace("%2", teamNames[1]);

                  if (keyValue.includes("%P") && keyStrings[1] !== "") {
                    keyValue = keyValue.replace("%P", keyStrings[1]);
                  }

                  console.log(outcomeGroup, 'outcomeGroup')

                  return (
                    <Box key={key} className="flex items-center gap-[12px] border-b-grey-200 border-b-1 py-[30px]">
                      <Box className="w-[200px]">{keyValue}</Box>
                      <Box className="flex-grow flex justify-around">
                        <Box className="flex justify-between gap-[24px] flex-wrap max-w-[580px]">
                          {outcomes.map(out => {
                            const outKey = Object.keys(out)[0];
                            
                            const teamNameVisible = Number(outKey) >= 0 && outKey !== "12";

                            const value = key.split(",")[1] || "";
                            let label = teamNameVisible ? teamNames[`${+outKey - 1}`] : `${outcomeLabels[outKey]} ${value ? ("(" + value + ")") : ""}`;
                            if (key === "Score") {
                              label = outKey;
                            } else if (key.startsWith("goal number")) {
                              if (outKey === "%1") {
                                label = teamNames[0];
                              } else if (outKey === "%2") {
                                label = teamNames[1];
                              } else {
                                label = outKey
                              }
                            }

                            return (
                              <OutcomeSelect
                                label={label}
                                selectedValue={out[outKey]}
                                onChange={e => handleOutcomeChange(`${key};${outKey}`, e.value)}
                              />
                            );
                          })}
                          {/* <OutcomeSelect label="Turan Tovuz" />
                            <OutcomeSelect label="X" />
                            <OutcomeSelect label="Kapaz" /> */}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: 'fit-content',
                    alignSelf: 'flex-end',
                  }}
                  onClick={handleSave}
                >
                  Save
                </Button>
                {/* <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Match Result (1X2)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Turan Tovuz" />
                      <OutcomeSelect label="X" />
                      <OutcomeSelect label="Kapaz" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Total (2.5)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Under (2.5)" />
                      <OutcomeSelect label="Over (2.5)" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Match Result (1X2)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Turan Tovuz" />
                      <OutcomeSelect label="X" />
                      <OutcomeSelect label="Kapaz" />
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-[12px]">
                  <Box className="w-[200px]">Total (2.5)</Box>
                  <Box className="flex-grow">
                    <Box className="flex justify-between gap-[24px]">
                      <OutcomeSelect label="Under (2.5)" />
                      <OutcomeSelect label="Over (2.5)" />
                    </Box>
                  </Box>
                </Box> */}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}
