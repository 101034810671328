import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { getAdapter } from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import Alert from "@mui/material/Alert";

const api_domain = jwtServiceConfig.apiUrl;

export const getList = createAsyncThunk(
  "financeApp/balance/getList",
  async (body) => {
    try {
      let url = "";
      if (body.username === undefined) body.username = "";
      if (body.user.role == "betshop_operator" || body.user.role == "betshop_staff") {
        if (body.type == "admin") {
          if (body.username && body.username != "")
            url = `${api_domain}betshop/admin/list/${body.username}`;
          else url = `${api_domain}betshop/admin/list`;
        } else if (body.type == "supplier") {
          url = `${api_domain}betshop/supplier/list/${body.username}`;
          if (body.adminId)
            url = `${api_domain}betshop/supplier/list/${body.username}/${body.adminId}`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop/user/list/${body.username}`;
          if (body.adminId) {
            url = `${api_domain}betshop/user/list/${body.username}/${body.adminId}`
          };
          if (body.supplierId) {
            url = `${api_domain}betshop/user/list/${body.username}/${body.adminId}/${body.supplierId}`
          };

          url += `?page=${body.pageNumber}&limit=${body.pageLimit}`
        }
      } else if (body.user.role == "betshop_admin") {
        if (body.type == "supplier") {
          url = `${api_domain}betshop_admin/supplier/list/${body.username}`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop_admin/user/list/${body.username}`;
          if (body.supplierId)
            url = `${api_domain}betshop_admin/user/list/${body.username}/${body.supplierId}`;
        }
      } else if (body.user.role == "betshop_supplier") {
        url = `${api_domain}betshop_supplier/user/list/${body.username}`;
      }
      const response = await axios.get(url);
      let data = {};
      if (body.type == "admin") {
        data = response.data.betshop_admin || [];
      } else if (body.type == "supplier") {
        data = response.data.betshop_suppliers || [];
      } else if (body.type == "user") {
        let users = (response.data.users === undefined ? null : response.data.users);
        let total_count = (response.data.paginator === undefined ? 0 : response.data.paginator.total_count)
        data = { users, total_count };
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addBalance = createAsyncThunk(
  "financeApp/balance/addBalance",
  async (body) => {
    try {
      console.log("BODY", body);
      let url = "";
      if (body.role == "betshop_operator") {
        if (body.type == "admin") {
          url = `${api_domain}betshop/admin/addBalance`;
        } else if (body.type == "supplier") {
          url = `${api_domain}betshop/supplier/addBalance`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop/user/addBalance`;
        }
      } else if (body.role == "betshop_admin") {
        if (body.type == "supplier") {
          url = `${api_domain}betshop_admin/supplier/addBalance`;
        } else if (body.type == "user") {
          url = `${api_domain}betshop_admin/user/addBalance`;
        }
      } else if (body.role == "betshop_supplier") {
        if (body.type == "user") {
          url = `${api_domain}betshop_supplier/user/addBalance`;
        }
      }

      const response = await axios.put(url, body);
      const data = await response.data;
      if (body.transaction_type == "Deposit") {
        toast.success('Balance has been successully added', {
          duration: 2000,
          position: 'top-center',
          icon: null,
          style: {
              fontSize: '16px',
              background: '#4caf50',
              color: '#fff',
              padding: '16px',
              borderRadius: '8px',
              minWidth: '200px', // Minimum width for the toast
              maxWidth: '600px', // Maximum width for longer text
              whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
              textAlign: 'center'
          },
      });
      } else {
        toast.success('Balance has been successfully withdrawn', {
          duration: 2000,
          position: 'top-center',
          icon: null,
          style: {
            fontSize: '16px',
            background: '#4caf50',
            color: '#fff',
            padding: '16px',
            borderRadius: '8px',
            minWidth: '200px', // Minimum width for the toast
            maxWidth: '600px', // Maximum width for longer text
            whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
            textAlign: 'center'
          },
        });
      }
      return data;
    } catch (err) {
      console.log(err, 'error')
      toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
    }
  }
);

const balanceAdapter = createEntityAdapter({});

export const { selectAll: selectAllList } = balanceAdapter.getSelectors(
  (state) => state.financeApp.balance
);

const balanceSlice = createSlice({
  name: "financeApp/balance",
  initialState: balanceAdapter.getInitialState({
    pageNumber: 0,
    pageLimit: 10,
    totalCount: 0,
  }),
  reducers: {
    setAll: balanceAdapter.setAll,
    setPageNumber: {
      reducer: (state, action) => {
        state.pageNumber = action.payload;
      },
      prepare: (pageNumber) => {
        return {
          payload: pageNumber,
        };
      },
    },
    setPageLimit: {
      reducer: (state, action) => {
        state.pageLimit = action.payload;
      },
      prepare: (pageLimit) => {
        return {
          payload: pageLimit,
        };
      },
    },
  },
  extraReducers: {
    [getList.fulfilled]: (state, action) => {
      if (!action.payload) {
        balanceAdapter.setAll(state, []);
        state.totalCount = 0; // Set total count
      } else {
        console.log(action.payload, 'action.payload.users')
        if (action.payload.users !== undefined) {
          balanceAdapter.setAll(state, action.payload.users || {});
          state.totalCount = action.payload.total_count;
        } else {
          balanceAdapter.setAll(state, action.payload);
        }
      }
    },
  },
});

export const { setPageNumber, setPageLimit } = balanceSlice.actions;

export const selectPageNumber = ({ financeApp }) => {
 console.log(financeApp.balance, 'financeApp')
 return financeApp.balance.pageNumber;
}
export const selectPageLimit = ({ financeApp }) =>
  financeApp.balance.pageLimit;
export const selectTotalCount = ({ financeApp }) =>
  financeApp.balance.totalCount;

export default balanceSlice.reducer;
