import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from "../../../auth/services/jwtService/jwtServiceConfig";
import { toast } from 'react-hot-toast';
const api_domain = jwtServiceConfig.apiUrl;

export const getMarkets = createAsyncThunk('sportDataApp/markets/getMarkets', async ({ groupType, currentLanguageId, pageNumber, pageLimit }) => {
  const response = await axios.get(`${api_domain}markets/groupId/${groupType}/languageId/${currentLanguageId}?page=${pageNumber}&limit=${pageLimit}`);
  return {
    markets: response.data.markets,
    total_count: response.data.paginator.total_count,
  };
});

export const setMarketActive = createAsyncThunk('sportDataApp/sportEvent/setActive',
  async (id) => {
    try {
      const response = await axios.get(`${api_domain}markets/setActive/id/${id}`);
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  });

export const setMarketInactive = createAsyncThunk('sportDataApp/sportEvent/setInactive',
  async (id) => {
    try {
      const response = await axios.get(`${api_domain}markets/setInactive/id/${id}`);
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  });

export const setCashOutActive = createAsyncThunk('sportDataApp/sportEvent/setCashOutActive',
  async (id) => {
    try {
      const response = await axios.get(`${api_domain}markets/setCashOutActive/id/${id}`);
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  });

export const setCashOutInactive = createAsyncThunk('sportDataApp/sportEvent/setCashOutInactive',
  async (id) => {
    try {
      const response = await axios.get(`${api_domain}markets/setCashOutInactive/id/${id}`);
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  });

const marketsAdapter = createEntityAdapter({});

export const {
  selectById: selectMarketById,
  selectIds: selectMarketIds,
  selectEntities: selectMarketEntities,
  selectAll: selectAllMarkets,
  selectTotal: selectTotalMarkets,
} = marketsAdapter.getSelectors((state) => state.sportDataApp.markets);

const marketsSlice = createSlice({
  name: 'sportDataApp/markets',
  initialState: marketsAdapter.getInitialState({
    totalCount: 0,
  }),
  reducers: {},
  extraReducers: {
    [getMarkets.fulfilled]: (state, action) => {
      if (!action.payload.markets) {
        marketsAdapter.setAll(state, {})
        state.totalCount = 0;
      } else {
        marketsAdapter.setAll(state, action.payload.markets)
        state.totalCount = action.payload.total_count;
      }

    }
  },
});

export const selectTotalCount = ({ sportDataApp }) =>
  sportDataApp.markets.totalCount;

export default marketsSlice.reducer;