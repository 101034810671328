import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getProviders = createAsyncThunk('sportDataApp/providers/getProviders', async () => {
  const response = await axios.get(`${api_domain}providers`);
  return response.data.providers;
});

const providersAdapter = createEntityAdapter({});

export const {
  selectById: selectProviderById,
  selectIds: selectProviderIds,
  selectEntities: selectProviderEntities,
  selectAll: selectAllProviders,
  selectTotal: selectTotalProviders,
} = providersAdapter.getSelectors((state) => state.sportDataApp.providers);

const providerSlice = createSlice({
  name: 'sportDataApp/providers',
  initialState: providersAdapter.getInitialState({
    providerId: 0,
  }),
  reducers: {
    setProviderId: {
      reducer: (state, action) => {
        state.providerId = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || 0 }),
    },
  },
  extraReducers: {
    [getProviders.fulfilled]: (state, action) => {
      providersAdapter.upsertMany(state, action.payload)
    }
  },
});

export const { setProviderId } = providerSlice.actions;
export const selectProviderId = ({ sportDataApp }) => sportDataApp.providers.providerId;
export default providerSlice.reducer;