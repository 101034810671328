import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getSportEvent = createAsyncThunk('sportDataApp/sportEvent/getSportEvent', async (sportEventId) => {
  const response = await axios.get(`${api_domain}sport/event/eventId/${sportEventId}`);
  const data = await response.data;

  return data === undefined ? null : data.sport_event;
});

const sportEventSlice = createSlice({
  name: 'sportDataApp/sportEvent',
  initialState: null,
  reducers: {
  },
  extraReducers: {
    [getSportEvent.fulfilled]: (state, action) => action.payload,
  },
});

export const selectSportEvent = ({ sportDataApp }) => sportDataApp.sportEvent;

export default sportEventSlice.reducer;