import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const FinanceReport = lazy(() => import('./finance-report'));
const MySite = lazy(() => import('./my-site'));
const Users = lazy(() => import('./users'));

const affiliateConfigs = [{
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'affiliate/my-sites',
      element: <MySite />,
    },
    {
      path: 'affiliate/users',
      element: <Users />,
    },
    {
      path: '/affiliate/finance-report',
      element: <FinanceReport />,
    },
  ],
}];

export default affiliateConfigs;
