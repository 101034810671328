import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from "../../../auth/services/jwtService/jwtServiceConfig";
const api_domain = jwtServiceConfig.apiUrl;

export const getTournaments = createAsyncThunk('sportDataApp/tournaments/getTournaments', async ({sportId, countryId, currentLanguageId}) => {
  const response = await axios.get(`${api_domain}tournaments/languageId/${currentLanguageId}/sportId/${sportId}/countryId/${countryId}`);
  return response.data.tournaments;
});

const tournamentsAdapter = createEntityAdapter({});

export const {
  selectById: selectTournamentById,
  selectIds: selectTournamentIds,
  selectEntities: selectTournamentEntities,
  selectAll: selectAllTournaments,
  selectTotal: selectTotalTournaments,
} = tournamentsAdapter.getSelectors((state) => state.sportDataApp.tournaments);

const tournamentsSlice = createSlice({
  name: 'sportDataApp/tournaments',
  initialState: tournamentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    /*[getTournaments.fulfilled]: (state, action) => {
      tournamentsAdapter.upsertMany(state, action.payload)
    }*/

    [getTournaments.fulfilled]: (state, action) => {
      if (!action.payload) {
        tournamentsAdapter.setAll(state, {})
      } else {
        tournamentsAdapter.setAll(state, action.payload)
      }

    }
  },
});

export default tournamentsSlice.reducer;