import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getAllOperatorTransactions = createAsyncThunk('financeApp/transactionsLogs/getAllOperatorTransactions', async () => {
    try {
        const response = await axios.get(`${api_domain}meganet/finance/transactions`);
        const data = await response.data.transactions || [];
        return data;
    } catch (error) {
        console.error(error)
    }
});

export const getFilteredTransactionsLog = createAsyncThunk('financeApp/transactionsLogs/getFilteredTransactionsLog', async ({ filter }) => {
    try {
        const response = await axios.get(`${api_domain}meganet/finance/transactions/${filter.system}/${filter.startDate}/${filter.endDate}`);
        const data = response.data.transactions || [];
        return data;
    } catch (err) {
        toast.error(`${err.response.data.error}`, {
            duration: 2000, // Adjust the duration (in milliseconds) as needed
            position: 'top-center', // Position of the toast
            style: {
                fontSize: '18px',
                background: '#f44336', // Change the background color (e.g., red for error)
                color: '#fff', // Set text color for contrast
                padding: '8px',
                paddingInline: '50px',
                borderRadius: '8px',
            },
        });
        return [];
    }

});

const transactionsLogsAdapter = createEntityAdapter({});

export const {
    selectById: selectDictionaryById,
    selectIds: selectDictionaryIds,
    selectAll: selectAllTransactionsLogs,
} = transactionsLogsAdapter.getSelectors((state) => state.financeApp.transactionsLogs);

const transactionsLogsSlice = createSlice({
    name: 'financeApp/transactionsLogs',
    initialState: transactionsLogsAdapter.getInitialState({}),
    reducers: {},
    extraReducers: {
        [getFilteredTransactionsLog.fulfilled]: (state, action) => {
            if (!action.payload) {
                transactionsLogsAdapter.setAll(state, {});
            } else {
                transactionsLogsAdapter.setAll(state, action.payload);
            }
        },
        [getAllOperatorTransactions.fulfilled]: (state, action) => {
            if (!action.payload) {
                transactionsLogsAdapter.setAll(state, {});
            } else {
                transactionsLogsAdapter.setAll(state, action.payload);
            }
        },
    },
});

export default transactionsLogsSlice.reducer;