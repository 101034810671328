// const jwtServiceConfig = {
//   signIn: process.env.REACT_APP_API_URL + "/api/auth/sign-in",
//   signUp: process.env.REACT_APP_API_URL + "/api/auth/sign-up",
//   signUpAffiliate:
//     process.env.REACT_APP_API_URL + "/api/auth/affiliate/sign-up",
//   accessToken: process.env.REACT_APP_API_URL + "/api/auth/refresh-token",
//   // accessToken: 'api/auth/access-token',
//   updateUser: process.env.REACT_APP_API_URL + "/api/auth/user/update",
//   apiUrl: process.env.REACT_APP_API_URL,
// };

// export default jwtServiceConfig;

const jwtServiceConfig = {
  signIn: process.env.REACT_APP_API_URL + "auth/sign-in",
  signUp: process.env.REACT_APP_API_URL + "auth/sign-up",
  signUpAffiliate: process.env.REACT_APP_API_URL + "auth/affiliate/sign-up",
  accessToken: process.env.REACT_APP_API_URL + "auth/refresh-token",
  // accessToken: 'api/auth/access-token',
  updateUser: process.env.REACT_APP_API_URL + "auth/user/update",
  cdnUrl: process.env.REACT_APP_API_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  testLoginUser: process.env?.TEST_LOGIN_USER
    ? process.env.TEST_LOGIN_USER
    : "",
  testLoginPwd: process.env?.TEST_LOGIN_PWD ? process.env.TEST_LOGIN_PWD : "",
};

export default jwtServiceConfig;
