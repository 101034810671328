import { authRoles } from "src/app/auth";

const headRowsData = () => {
  return [
    {
      id: "event_id",
      align: "left",
      disablePadding: false,
      label: "Event ID",
      translation: "Event_ID",
      width: "120px",
    },
    {
      id: "start_date",
      align: "left",
      disablePadding: false,
      label: "Start Date",
      translation: "Start_Date",
      sort: true,
      width: "12%",
    },
    {
      id: "event_name",
      align: "left",
      disablePadding: false,
      label: "Event Name",
      translation: "Event_Name",
      sort: true,
    },
    {
      id: "sport_type",
      align: "left",
      disablePadding: false,
      label: "Sport Type",
      translation: "Sport_Type",
      sort: true,
      width: "12%",
    },
    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
      translation: "Country",
      sort: true,
    },
    {
      id: "tournament",
      align: "left",
      disablePadding: false,
      label: "Tournament/League",
      translation: "Tournament/League",
      sort: true,
    },
    {
      id: "active",
      align: "left",
      disablePadding: false,
      label: "Active",
      translation: "Active",
      sort: true,
    },
  ]
};

export { headRowsData };
