import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getCountries = createAsyncThunk('sportDataApp/countries/getCountries', async ({sportId, currentLanguageId}) => {
  const response = await axios.get(`${api_domain}countries/${currentLanguageId}/${sportId}`);
  return await response.data.countries;
});

const countriesAdapter = createEntityAdapter({});

export const {
  selectById: selectCountryById,
  selectIds: selectCountryIds,
  selectEntities: selectCountryEntities,
  selectAll: selectAllCountries,
  selectTotal: selectTotalCountries,
} = countriesAdapter.getSelectors((state) => state.sportDataApp.countries);

const countrySlice = createSlice({
  name: 'sportDataApp/countries',
  initialState: countriesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [getCountries.fulfilled]: (state, action) => {
      if (!action.payload) {
        countriesAdapter.setAll(state, {})
      } else {
        countriesAdapter.setAll(state, action.payload)
      }
    }
  },
});

export default countrySlice.reducer;