import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getSportEvents = createAsyncThunk(
  "sportDataApp/sportEvents/getSportEvents",
  async ({
    providerId,
    betType,
    sportId,
    countryId,
    leagueId,
    currentLanguageId,
    pageNumber,
    pageLimit
  }) => {
    const response = await axios.get(
      `${api_domain}sportEvents/languageId/${currentLanguageId}/providerId/${providerId}/betType/${betType}/sportId/${sportId}/countryId/${countryId}/leagueId/${leagueId}?page=${pageNumber}&limit=${pageLimit}`
    );
    return {
      sport_events: response.data.sport_events,
      total_count: response.data.paginator.total_count,
    };
  }
);

export const setSportEventActive = createAsyncThunk(
  "sportDataApp/sportEvent/setActive",
  async (id) => {
    try {
      const response = await axios.get(
        `${api_domain}sportEvent/setActive/id/${id}`
      );
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  }
);

export const setSportEventBoosted = createAsyncThunk(
  "sportDataApp/sportEvent/setBoosted",
  async (id) => {
    try {
      const response = await axios.get(
        `${api_domain}sportEvent/setBoosted/id/${id}`
      );

      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }

    return response.data.id;
  }
);

export const setSportEventInactive = createAsyncThunk(
  "sportDataApp/sportEvent/setInactive",
  async (id) => {
    try {
      const response = await axios.get(
        `${api_domain}sportEvent/setInactive/id/${id}`
      );
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  }
);

export const setSportEventUnboosted = createAsyncThunk(
  "sportDataApp/sportEvent/setUnboosted",
  async (id) => {
    try {
      const response = await axios.get(
        `${api_domain}sportEvent/setUnboosted/id/${id}`
      );
      toast.success('Status has been successfully changed', {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
      return response.data.id;
    } catch (err) {
      if (err?.response?.data?.status) {
        toast.error(`${err.response.data.user_error}`, {
          duration: 2000, // Adjust the duration (in milliseconds) as needed
          position: 'top-center', // Position of the toast
          style: {
            fontSize: '18px',
            background: '#f44336', // Change the background color (e.g., red for error)
            color: '#fff', // Set text color for contrast
            padding: '8px',
            paddingInline: '50px',
            borderRadius: '8px',
          },
        });
        return err?.response;
      }
    }
  }
);

const sportEventsAdapter = createEntityAdapter({});

export const {
  selectById: selectSportEventById,
  selectIds: selectSportEventIds,
  selectEntities: selectSportEventEntities,
  selectAll: selectAllSportEvents,
  selectTotal: selectTotalSportEvents,
} = sportEventsAdapter.getSelectors((state) => state.sportDataApp.sportEvents);

const sportEventsSlice = createSlice({
  name: "sportDataApp/sportEvents",
  initialState: sportEventsAdapter.getInitialState({
    eventType: "Live",
    currentSportId: 0,
    currentCountryId: 0,
    currentLeagueId: 0,
    pageNumber: 0,
    pageLimit: 10,
    totalCount: 0,
  }),
  reducers: {
    setEventType: {
      reducer: (state, action) => {
        state.eventType = action.payload;
      },
      prepare: (eventType) => {
        return {
          payload: eventType,
        };
      },
    },
    setCurrentSportId: {
      reducer: (state, action) => {
        state.currentSportId = action.payload;
      },
      prepare: (currentSportId) => {
        return {
          payload: currentSportId,
        };
      },
    },
    setCurrentCountryId: {
      reducer: (state, action) => {
        state.currentCountryId = action.payload;
      },
      prepare: (currentCountryId) => {
        return {
          payload: currentCountryId,
        };
      },
    },
    setCurrentLeagueId: {
      reducer: (state, action) => {
        state.currentLeagueId = action.payload;
      },
      prepare: (currentLeagueId) => {
        return {
          payload: currentLeagueId,
        };
      },
    },
    setPageNumber: {
      reducer: (state, action) => {
        state.pageNumber = action.payload;
      },
      prepare: (pageNumber) => {
        return {
          payload: pageNumber,
        };
      },
    },
    setPageLimit: {
      reducer: (state, action) => {
        state.pageLimit = action.payload;
      },
      prepare: (pageLimit) => {
        return {
          payload: pageLimit,
        };
      },
    },
  },
  extraReducers: {
    [getSportEvents.fulfilled]: (state, action) => {
      if (!action.payload.sport_events) {
        sportEventsAdapter.setAll(state, {});
        state.totalCount = 0;
      } else {
        sportEventsAdapter.setAll(state, action.payload.sport_events);
        state.totalCount = action.payload.total_count;
      }
    },
  },
});

export const {
  setEventType,
  setCurrentSportId,
  setCurrentCountryId,
  setCurrentLeagueId,
  setPageNumber,
  setPageLimit,
} = sportEventsSlice.actions;
export const selectEventType = ({ sportDataApp }) =>
  sportDataApp.sportEvents.eventType;
export const selectCurrentSportId = ({ sportDataApp }) =>
  sportDataApp.sportEvents.currentSportId;
export const selectCurrentCountryId = ({ sportDataApp }) =>
  sportDataApp.sportEvents.currentCountryId;
export const selectCurrentLeagueId = ({ sportDataApp }) =>
  sportDataApp.sportEvents.currentLeagueId;
export const selectPageNumber = ({ sportDataApp }) =>
  sportDataApp.sportEvents.pageNumber;
export const selectPageLimit = ({ sportDataApp }) =>
  sportDataApp.sportEvents.pageLimit;
export const selectTotalCount = ({ sportDataApp }) =>
  sportDataApp.sportEvents.totalCount;

export default sportEventsSlice.reducer;
