import '@mock-api';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection, loadResources } from 'app/store/i18nSlice';
import initI18next from 'src/i18n';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import settingsConfig from 'app/configs/settingsConfig';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';
import "rsuite/dist/rsuite.css";
import {selectFlatNavigation, selectNavigation, selectNavigationAll} from 'app/store/fuse/navigationSlice';
import {Toaster} from 'react-hot-toast'
import i18n from 'src/i18n';
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useState } from "react";
import i18next from "i18next";
import FuseLoading from "@fuse/core/FuseLoading";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen/FuseSplashScreen";
import * as React from "react";
import { useEffect } from 'react';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const setFavicon = (url) => {
  // Check if a favicon link already exists, and if not, create one
  let link = document.querySelector("link[rel*='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = url;
};

function App() {
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);
  const navigation = useSelector(selectNavigationAll);
  const lng = useSelector(selectCurrentLanguageId);
  const [translationsLoaded, setTranslationsLoaded] = useState(false)

  i18next.loadNamespaces('translation', (err) => {
    if (!translationsLoaded) {setTranslationsLoaded(true) }
  }).then(r  => {});

  useEffect(() => {
    // Determine the favicon based on the domain
    const hostname = window.location.hostname;
    let faviconUrl = "/favicon.ico"; // Default favicon path

    console.log(hostname, 'hostname')

    if (hostname.includes("meganetpanel.com")) {
      faviconUrl = "/favicon-example.ico";
    } else if (hostname.includes("betgardportal.com")) {
      faviconUrl = "/betgard_favicon.png";
    }
    // Set the favicon
    setFavicon(faviconUrl);
  }, []);

  return (translationsLoaded ?
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.role}
              userPermissions={user.permissions}
              navigation={navigation}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout layouts={themeLayouts} />
                <Toaster position='top-right' toastOptions={{ duration: 2 }} />
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
          :
      // <FuseLoading />
          <FuseSplashScreen />
  );
}

export default withAppProviders(App)();
