import { format } from "date-fns";

export const ifIncludes = (word, part) => {
  return word.toLowerCase().includes(part.toLowerCase());
};

export const isEqual = (a, b) => {
  return a.toLowerCase() === b.toLowerCase();
};

export const YMD = (date) => {
  return format(new Date(date), "yyyy-MM-dd");
}

export const getNormalDate = (date) => {
  return date.split(' ')[0]
}