import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import authRoles from "../../auth/authRoles";
import { ResultManagement } from './ResultManagement/ResultManagement';

const SportEvent = lazy(() => import('./event/SportEvent'));
const SportEvents = lazy(() => import('./events/SportEvents'));
const Settings = lazy(() => import('./settings/Settings'));
const BettingSetting = lazy(() => import('./betting_setting/BettingSetting'));
const FavouriteLeauge = lazy(() => import('./favouriteLeague/FavouriteLeague'));

const sportDataConfigs = [{
  settings: {
    layout: {},
  },
  auth: authRoles.manager_staff,
  routes: [
    {
      path: 'sport-data/events',
      element: <SportEvents />,
    },
    {
      path: 'sport-data/event/eventId/:eventId',
      element: <SportEvent />,
    },
    {
      path: 'sport-data/settings',
      element: <Settings />,
    },
    {
      path: 'sport-data/betting_setting',
      element: <BettingSetting />,
    },
    // {
    //   path: 'sport-data/favourite_league',
    //   element: <FavouriteLeauge />,
    // },
    {
      path: "sport-data/result-management",
      element: <ResultManagement />
    }
  ],
}];

export default sportDataConfigs;