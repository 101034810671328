import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { authRoles } from "src/app/auth";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getOnlineFinancialReports = createAsyncThunk(
  "online/getOnlineFinancialReports",
  async ({ filter }) => {
    const res = await axios.get(
      `${api_domain}online/finance/report/${filter.userName}/${filter.startDate}/${filter.endDate}`
    );
    return res.data.results || [];
  }
);

export const getBetShopFinancialReports = createAsyncThunk(
  "betshop/getBetShopFinancialReports",
  async ({ filter }) => {
    const { startDate, endDate, admin, supplier, userName } =
      filter;
    // betshop
    try {
      let res = [];

      if (admin === "all" || (admin === '' && supplier === '' && userName === '')) {
        res = await axios.get(
          `${api_domain}betshop/report/finance/${startDate}/${endDate}/all`
        );
      } else if (admin !== '' && supplier === "all") {
        res = await axios.get(
          `${api_domain}betshop/report/finance/${startDate}/${endDate}/admin/${admin}`
        );
      } else if (userName === "") {
        res = await axios.get(
          `${api_domain}betshop/report/finance/${startDate}/${endDate}/supplier/${supplier}`
        );
      } else {
        res = await axios.get(
          `${api_domain}betshop/report/finance/${startDate}/${endDate}/user/${userName}`
        );
      }
      return res.data.results || [];
    } catch (_) {
      //
      return [];
    }
  }
);

const financialReportAdapter = createEntityAdapter({
  selectId: (result) => result.event_id,
});

export const { selectAll: selectFinancialReports } =
  financialReportAdapter.getSelectors((state) => state.onlineOperator.results);

const reportSlice = createSlice({
  name: "online/results",
  initialState: financialReportAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(getOnlineFinancialReports.fulfilled, (state, action) => {
      financialReportAdapter.setAll(state, action.payload || []);
    });
  },
});

export default reportSlice.reducer;
