import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig';

const api_domain = jwtServiceConfig.apiUrl;

export const getGroups = createAsyncThunk('sportDataApp/groups/getGroups', async ({currentLanguageId}) => {
  const response = await axios.get(`${api_domain}groups/${currentLanguageId}`);
  return response.data.groups;
});

const groupsAdapter = createEntityAdapter({});

export const {
  selectById: selectGroupById,
  selectIds: selectGroupIds,
  selectEntities: selectGroupEntities,
  selectAll: selectAllGroups,
  selectTotal: selectTotalGroups,
} = groupsAdapter.getSelectors((state) => state.sportDataApp.groups);

const groupsSlice = createSlice({
  name: 'sportDataApp/groups',
  initialState: groupsAdapter.getInitialState({
    groupId: 0,
  }),
  reducers: {
    setGroupId: {
      reducer: (state, action) => {
        state.groupId = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || 0 }),
    },},
  extraReducers: {
    // [getGroups.fulfilled]: (state, action) => {
    //   groupsAdapter.upsertMany(state, action.payload)
    // }
    [getGroups.fulfilled]: (state, action) => {
      if (!action.payload) {
        groupsAdapter.setAll(state, {})
      } else {
        groupsAdapter.setAll(state, action.payload)
      }
    },
  },
});

export const { setGroupId } = groupsSlice.actions;
export const selectGroupId = ({ groupDataApp }) => groupDataApp.providers.groupId;

export default groupsSlice.reducer;