import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import jwtServiceConfig from 'src/app/auth/services/jwtService/jwtServiceConfig'

const api_domain = jwtServiceConfig.apiUrl

export const getFilteredSystemFinancial = createAsyncThunk(
	'financeApp/systemFinancials/getFilteredSystemFinancial',
	async ({ filter }) => {
		let url = `${api_domain}meganet/finance/statistics/?`
		if (filter.systemType) url += `systemId=${filter.systemType}&`
		if (filter.game) url += `game=${filter.game}&`
		if (filter.startDate) url += `startDate=${filter.startDate}&`
		if (filter.endDate) url += `endDate=${filter.endDate}`

		const response = await axios.get(url)
		const data = response.data.system_financial
		return data === undefined ? null : data
	}
)

export const getSystemFinancials = createAsyncThunk(
	'financeApp/systemFinancials/getSystemFinancials',
	async body => {
		const response = await axios.get(
			`${api_domain}meganet/finance/statistics`
		)
		const data = response.data.system_financial
		return data === undefined ? null : data
	}
)

export const resetBalance = createAsyncThunk(
	'financeApp/systemFinancials/resetBalance',
	async body => {
		try {
			const response = await axios.get(
				`${api_domain}betshop/balance/reset`
			)

			toast.success('Balance has successfully reset', {
				duration: 2000,
				position: 'top-center',
				icon: null,
				style: {
					fontSize: '16px',
					background: '#4caf50',
					color: '#fff',
					padding: '16px',
					borderRadius: '8px',
					minWidth: '200px', // Minimum width for the toast
					maxWidth: '600px', // Maximum width for longer text
					whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
					textAlign: 'center'
				},
			});

			return response === undefined ? null : response
		} catch (err) {
			if (err?.response?.data?.status) {
				toast.error(`${err.response.data.user_error}`, {
					duration: 2000, // Adjust the duration (in milliseconds) as needed
					position: 'top-center', // Position of the toast
					style: {
						fontSize: '18px',
						background: '#f44336', // Change the background color (e.g., red for error)
						color: '#fff', // Set text color for contrast
						padding: '8px',
						paddingInline: '50px',
						borderRadius: '8px',
					},
				});
				return err?.response;
			}
		}
	}
)

const systemFinancialsAdapter = createEntityAdapter({})

export const {
	selectAll: selectAllSystemFinancials
} = systemFinancialsAdapter.getSelectors(
	state => state.financeApp.systemFinancials
)

const systemFinancialsSlice = createSlice({
	name: 'financeApp/systemFinancials',
	initialState: systemFinancialsAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getFilteredSystemFinancial.fulfilled]: (state, action) => {
			if (!action.payload) {
				systemFinancialsAdapter.setAll(state, {})
			} else {
				systemFinancialsAdapter.setAll(state, action.payload)
			}
		},
		[getSystemFinancials.fulfilled]: (state, action) => {
			if (!action.payload) {
				systemFinancialsAdapter.setAll(state, {})
			} else {
				systemFinancialsAdapter.setAll(state, action.payload)
			}
		},
	},
})

export default systemFinancialsSlice.reducer
