import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const api_domain = jwtServiceConfig.apiUrl;

export const getResultManagementData = createAsyncThunk(
  "results/getResultManagementData",
  async (body) => {
    let url = `${api_domain}sportData/getResultManagements?`
    if (body.startDate != undefined && body.startDate != "") url += `startDate=${body.startDate}&`;
    if (body.endDate!= undefined && body.endDate!= "") url += `endDate=${body.endDate}&`;
    if (body.betType!= undefined && body.betType!= "") url += `betType=${body.betType}&`;
    if (body.sport!= undefined && body.sport!= "") url += `sport=${body.sport}&`;
    if (body.country!= undefined && body.country!= "") url += `country=${body.country}&`;
    if (body.league!= undefined && body.league!= "") url += `league=${body.league}&`;
    if (body.status!= undefined && body.status!= "") url += `status=${body.status}`;
    const response = await axios.get(url);
    return response.data.results;
  })

export const getAllResultManagementData = createAsyncThunk(
  "results/getAllResultManagementData",
  async () => {
    const response = await axios.get(`${api_domain}sportData/getResultManagement/all`);
    return response.data.results;
  }
);

export const getSports = createAsyncThunk(
  "results/getSports",
  async ({ langId }) => {
    const response = await axios.get(`${api_domain}sports/${langId}`);
    return response.data.sports;
  }
);

export const getCountries = createAsyncThunk(
  "results/getCountries",
  async ({ langId, sportId }) => {
    const response = await axios.get(`${api_domain}countries/${langId}/${sportId}`);
    return response.data.countries;
  }
);

export const getLeagues = createAsyncThunk(
  "results/getLeagues",
  async ({ langId, sportId, countryId }) => {
    const response = await axios.get(`${api_domain}tournaments/languageId/${langId}/sportId/${sportId}/countryId/${countryId}`);
    return response.data.tournaments;
  }
);

export const setResultManagementData = createAsyncThunk(
  "results/setResultManagementData",
  async ({ event_id, body }) => {
    const response = await axios.post(`${api_domain}sportData/setResultManagement`, body);
    if (response.data.success) {
      toast.success(`Result has been successfully updated`, {
        duration: 2000,
        position: 'top-center',
        icon: null,
        style: {
          fontSize: '16px',
          background: '#4caf50',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
          minWidth: '200px', // Minimum width for the toast
          maxWidth: '600px', // Maximum width for longer text
          whiteSpace: 'pre-line', // Allow line breaks if the text is too long,
          textAlign: 'center'
        },
      });
    }
    return { event_id, body, data: response.data.results};
  }
);


const resultManagementAdapter = createEntityAdapter({
  selectId: result => result.event_id 
});

export const {
  selectAll: selectAllResults,
} = resultManagementAdapter.getSelectors((state) => state.sportDataApp.results);

const resultManagementSlice = createSlice({
  name: "sportDataApp/results",
  initialState: resultManagementAdapter.getInitialState(),
  reducers: {},
  // extraReducers: {    
  //   [getResultManagementData.fulfilled]: (state, action) => {
  //     if (!action.payload) {
  //       resultManagementAdapter.setAll(state, {})
  //     } else {
  //       resultManagementAdapter.setAll(state, action.payload)
  //     }
  //   }
  // },
  extraReducers: builder => {
    builder
      .addCase(getAllResultManagementData.fulfilled, (state, action) => {
        resultManagementAdapter.setAll(state, action.payload || []);
      })
      .addCase(getResultManagementData.fulfilled, (state, action) => {
        resultManagementAdapter.setAll(state, action.payload || []);
      })
      .addCase(setResultManagementData.fulfilled, (state, action) => {

        const item = state.entities[action.payload.event_id];
        if (item) {
          resultManagementAdapter.updateOne(state, {
            id: action.payload.event_id,
            changes: {
              ...item,
              outcomes: action.payload.body.outcome_infos.map(info => ({...info, outcome_info: info.outcome_ref_id, status: info.Status })),
            },
          });
        }
      })
  }
});

export default resultManagementSlice.reducer;
