import { selectUser } from "app/store/userSlice";
import { useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import { authRoles } from "../auth";

export const usePermissions = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const allowed = (permission) => {
    return (
      user.role === "online_operator" ||
      user.role === "betshop_operator" ||
      (!authRoles.all_staff.includes(user.role) && user.permissions.length === 0) ||
      user.permissions.includes(permission)
    );
  };

  const accessLog = () => allowed('access_log');
  const addAffiliate = () => allowed('add_affiliate');
  const addNewStaff = () => allowed('staff_list_view_change');
  const affiliatePageView = () => allowed('affiliate_page_view');
  const affiliateUserInfoChange = () => allowed('change_affiliate_status');
  const bonusConfigChange = () => allowed('bonus_config_change');
  const addBonus = () => allowed('add_bonus');
  const bonusConfigView = () => allowed('bonus_config_view');
  const bonusListForUserChange = () => allowed('bonus_offer_list_change');
  const bonusListForUserView = () => allowed('bonus_offer_list_view');
  const changeAffiliateStatus = () => allowed('change_affiliate_status');
  const changeUserStatus = () => allowed('change_user_status');
  const cmsPageChange = () => allowed('cms_page_change');
  const cmsPageView = () => allowed('cms_page_view');
  const couponManagementPageView = () => allowed('coupon_management_page_view');
  const depositStatusChange = () => allowed('deposit_status_change');
  const depositView = () => allowed('deposit_view');
  const favorLeaguesStatusChange = () => allowed('favorite_league_view_change');
  const favorLeaguesView = () => allowed('favorite_league_view_change');
  const financialReportPageView = () => allowed('financial_report_page_view');
  const betslipReportPageView = () => allowed('betslip_report_page_view');
  const casinoReportPageView = () => allowed('casino_report_page_view');
  const globalSettingChangeView = () => allowed('global_setting_change_view');
  const listOffersForUsersChange = () => allowed('list_offers_for_users_change');
  const listOffersForUsersView = () => allowed('list_offers_for_users_view');
  const onlineUserDetailsViewStatusChange = () => allowed('user_status_and_detail_change');
  const operatorSettingPageChange = () => allowed('operator_setting_page_view_and_change');
  const operatorSettingPageView = () => allowed('operator_setting_page_view_and_change');
  const reportPageView = () => allowed('report_page_view');
  const slipSettlementManagementPageView = () => allowed('slip_settlement_management_page_view');
  const sportEventsDataPageView = () => allowed('sport_events_data_page_view');
  const sportEventStatusChange = () => allowed('sport_event_status_change');
  const staffListView = () => allowed('staff_list_view_change');
  const staffStatusChange = () => allowed('staff_list_view_change');
  const transactionPageView = () => allowed('transaction_page_view');
  const userContactInfoChange = () => allowed('user_contact_info_change');
  const userContactInfoView = () => allowed('user_contact_info_view');
  const userInfoChange = () => allowed('user_info_change');
  const userInfoView = () => allowed('user_info_view');
  const usersBettingSettingChange = () => allowed('betting_setting_view_change');
  const usersBettingSettingView = () => allowed('betting_setting_view_change');
  const withdrawStatusChange = () => allowed('withdraw_status_change');
  const withdrawView = () => allowed('withdraw_view');
  const createChangeSystemStatus = () => allowed('create_change_system_status');
  const addCreditBalanceForSystems = () => allowed('add_credits_balance_for_systems');
  const dictionaryViewAndChange = () => allowed('theme_dictionary_view_and_change');
  const userListView = () => allowed('user_list_view');
  const addBalance = () => allowed('add_balance');
  const betshopAdminSetting = () => allowed('betshop_admin_setting');
  const betshopSupplierSetting = () => allowed('betshop_supplier_setting');
  const betshopUserSetting = () => allowed('betshop_user_setting');

  const checkAndRedirect = (callback, redirectTo="/dashboards") => {
    if (!callback()) {
      setTimeout(() => {
        try {
          console.log(redirectTo, 'redirectTo')
          navigate(redirectTo);
        } catch(err) {
          console.log(err, 'redirect')
        }
      }, 100);
    }
  }

  return {
    checkAndRedirect,
    accessLog,
    addAffiliate,
    addNewStaff,
    affiliatePageView,
    affiliateUserInfoChange,
    bonusConfigChange,
    bonusConfigView,
    addBonus,
    bonusListForUserChange,
    bonusListForUserView,
    changeAffiliateStatus,
    changeUserStatus,
    cmsPageChange,
    cmsPageView,
    couponManagementPageView,
    depositStatusChange,
    depositView,
    favorLeaguesStatusChange,
    favorLeaguesView,
    financialReportPageView,
    betslipReportPageView,
    casinoReportPageView,
    globalSettingChangeView,
    listOffersForUsersChange,
    listOffersForUsersView,
    onlineUserDetailsViewStatusChange,
    operatorSettingPageChange,
    operatorSettingPageView,
    reportPageView,
    slipSettlementManagementPageView,
    sportEventsDataPageView,
    sportEventStatusChange,
    staffListView,
    staffStatusChange,
    transactionPageView,
    userContactInfoChange,
    userContactInfoView,
    userInfoChange,
    userInfoView,
    usersBettingSettingChange,
    usersBettingSettingView,
    withdrawStatusChange,
    withdrawView,
    createChangeSystemStatus,
    addCreditBalanceForSystems,
    dictionaryViewAndChange,
    userListView,
    addBalance,
    betshopAdminSetting,
    betshopSupplierSetting,
    betshopUserSetting
  };
};
