import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import Autocomplete from "react-autocomplete";

export const AutoCompleteGroup = ({
  label,
  items,
  onSelect = () => { },
  onTextChange = () => { },
  fullWidth,
  textValue,
  nested
}) => {
  const [value, setValue] = useState('');

  const handleSelect = (value, item) => {
    setValue(value);
    onTextChange(item.label);
    onSelect(item);
  }

  const handleChange = (event) => {
    const text = event.target.value;
    setValue(text);
    onTextChange(text);

    const item = items.find(it => it.label === text);
    if (item) {
      onSelect(item);
    }
  }

  return (
    <Stack className={`min-w-fit ${fullWidth && "w-full"} z-10 relative`} spacing="4px" alignItems="flex-start">
      <Typography className="text-[#505155] text-[11px] capitalize">{label}</Typography>
      <Autocomplete
        inputProps={{
          className:
            "border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[12px] py-[8px] bg-white text-[#9EAAB1] w-full text-black z-10",
        }}
        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={(item) => item.label}
        items={items}
        renderItem={(item, isHighlighted) => (
          <div
            style={{ background: isHighlighted ? "lightgray" : "white" }}
            className="px-[12px] py-[8px] bg-white text-[#222] w-full"
          >
            {item.label}
          </div>
        )}
        menuStyle={{
          maxHeight: '250px',
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'absolute',
          top: '40px',
          left: 0,
          overflow: 'auto',
          zIndex: 1000
        }}
        wrapperStyle={{
          width: '100%',
          zIndex: 100,
          position: 'relative'
        }}
        value={nested ? textValue : value}
        onChange={handleChange}
        onSelect={handleSelect}
      />
    </Stack>
  );
};
