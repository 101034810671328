import { combineReducers } from '@reduxjs/toolkit'
import betslipReports from "./betslipReportSlice";
import casinoReports from './casinoReportSlice';
import financialReports from './financialReportSlice';

const reducer = combineReducers({
	betslipReports,
	casinoReports,
	financialReports
})

export default reducer
