import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import Alert from "@mui/material/Alert";

const api_domain = jwtServiceConfig.apiUrl;

export const getFavouriteLeagues = createAsyncThunk(
  "sportDataApp/leagues/getFavouriteLeagues",
  async ({bet_type, languageId, sportId, countryId}) => {
    const response = await axios.get(`${api_domain}favourites/${languageId}/${bet_type}/${sportId}/${countryId}`);
    const data = await response.data.tournaments;
    return data === undefined ? null : data;
  }
);

export const setFavouriteLeagueStatus = createAsyncThunk(
  "sportDataApp/leagues/setFavouriteLeagueStatus",
  async ({bet_type, tournamentId, active}) => {
    const response = await axios.get(`${api_domain}favourites/${bet_type}/${tournamentId}/${active}`);
    const data = await response.data.tournaments;
    return data === undefined ? null : data;
  }
);
// export const getCountriesBySport = createAsyncThunk(
//     "sportDataApp/leagues/getCountriesBySport",
//     async ({sportId, languageId}) => {
//       const response = await axios.get(
//         `${api_domain}betshop/setting/betting/countries/${sportId}/${languageId}`
//       );
//       const data = await response.data.countries;
//       return data === undefined ? null : data;
//     }
//   );

// export const getLeaguesBySport = createAsyncThunk(
//   "sportDataApp/leagues/getLeaguesBySport",
//   async ({sportId, languageId}) => {
//     const response = await axios.get(
//       `${api_domain}betshop/setting/betting/leagues/${sportId}/${languageId}`
//     );
//     const data = await response.data.system_tournaments;
//     return data === undefined ? null : data;
//   }
// );
// export const getLeaguesBySportCountry = createAsyncThunk(
//   "sportDataApp/leagues/getLeaguesBySportCountry",
//   async ({sportId, countryId, languageId}) => {
//     const response = await axios.get(
//       `${api_domain}betshop/setting/betting/leagues/${sportId}/${countryId}/${languageId}`
//     );
//     const data = await response.data.system_tournaments;
//     return data === undefined ? null : data;
//   }
// );

// export const setActive = createAsyncThunk(
//   "sportDataApp/leagues/setActive",
//   async (req) => {
//     try {
//       const response = await axios.get(
//         `${api_domain}betshop/setting/betting/leagues/setActive/${req.tournamentId}/${req.active}/${req.bet_type}`
//       );
//       toast.custom(
//         <Alert variant="filled" severity="success">
//           Active status has been successfully changed
//         </Alert>
//       );
//       return response.data.success;
//       // return response.data.success;
//     } catch (err) {
//       if (err?.response?.data?.status) {
//         toast.custom(
//           <Alert variant="filled" severity="error">{err.response.data.user_error}</Alert>
//         );
//         return err?.response;
//       }

//       return rejectWithValue(err.response.data);
//     }
//   }
// );

const leaguesAdapter = createEntityAdapter({});

export const {
  selectById: selectLeagueById,
  selectIds: selectLeagueIds,
  selectEntities: selectLeagueEntities,
  selectAll: selectAllLeagues,
  selectTotal: selectTotalLeagues,
} = leaguesAdapter.getSelectors((state) => state.sportDataApp.leagues);

const leaguesSlice = createSlice({
  name: "sportDataApp/leagues",
  initialState: leaguesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {    
    [getFavouriteLeagues.fulfilled]: (state, action) => {
      if (!action.payload) {
        leaguesAdapter.setAll(state, {})
      } else {
        leaguesAdapter.setAll(state, action.payload)
      }

    }
  },
});

export default leaguesSlice.reducer;
